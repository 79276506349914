import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import Image from '@/components/Image'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

export interface Gallery {
  images: any
}

const Gallery = ({ images }: Gallery) => {
  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <div className="grid grid-cols-4 gap-4">
          {images &&
            images.map((image: any, index: number) => {
              const currentimage: any = getImage(image?.mediaItem?.node?.localFile)

              return (
                <article className="col-span-4 sm:col-span-2 md:col-span-1" key={index}>
                  <a className="hover-scale-img" href={image?.mediaItem?.node?.localfile?.url}>
                    <Image alt={image?.mediaItem?.node?.altText} image={currentimage} />
                  </a>
                </article>
              )
            })}
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default Gallery
