import React from 'react'
import Layout from '@/components/Layout'
import { graphql } from 'gatsby'
import Gutenberg from '@/components/Gutenberg'
import Container from '@/components/Container'
import PageHeading from '@/components/PageHeading'
import { seo, wp, pagelang } from '@/types.d'

interface DefaultTemplateData {
  data: {
    page: {
      title: string
      blocks: any
      featuredImage: any
      language: pagelang
      translations: any
      uri: string
      seo: seo
      formHeading: string
      formOrder: string
      fromid: string
    }
    mainMenu: any
    langData: any
    instagram: any
    wp: wp
    pageForm: any
    form: any
  }
  pageContext: any
}

const DefaultTemplate = ({ data }: DefaultTemplateData) => {
  const { page, mainMenu, instagram, wp, form, pageForm } = data
  mainMenu.navButton = wp?.navButtons || ''
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }
  const blockdata = page.blocks

  return (
    <Layout
      form={form}
      featuredImage={page?.featuredImage}
      nav={mainMenu}
      lang={langData}
      instagram={instagram}
      cloudRight={true}
      uri={page?.uri}
      seodesc={page?.seo?.metaDesc}
      seokeywors={page?.seo?.metaKeywords}
      seotitle={page?.seo?.title}
    >
      <Container>
        <PageHeading className="max-w-[800px] mx-auto">{page?.title}</PageHeading>
      </Container>
      <Gutenberg
        form={pageForm}
        formid={page?.fromid}
        formHeading={page?.formHeading}
        formOrder={page?.formOrder}
        blocks={blockdata}
      />
    </Layout>
  )
}

export default DefaultTemplate

export const ContactTemplateQuery = graphql`
  query DefaultPage($id: String!, $menu: [WpMenuLocationEnum], $translang: String, $pageFromId: Int, $footerForm: Int) {
    page: wpPage(id: { eq: $id }) {
      ...featuredImagePage
      ...langdata
      ...seo
      id
      title
      uri
      formHeading: gwFormHeading
      formOrder: gwFormOrder
      fromid: gwPageForm
      ...gutenberg
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      ...subscribe
    }
    instagram: allInstagramContent(limit: 5) {
      ...instagram
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    pageForm: wpGravityFormsForm(formId: { eq: $pageFromId }) {
      ...GravityFormFields
    }
    form: wpGravityFormsForm(formId: { eq: $footerForm }) {
      ...GravityFormFields
    }
  }
`
