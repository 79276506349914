import React from 'react'
import Wysiwyg from '@/components/Wysiwyg'
import TextAndImage from '@/components/TextAndImage'
import Container from '@/components/Container'
import Gallery from '@/components/Gallery'
import CloudContainer from '@/components/CloudContainer'
import { getImage } from 'gatsby-plugin-image'
import Image from '@/components/Image'
import Onview from '@/components/Onview'
import FromBlock from '@/components/FormBlock'

interface GutenbergData {
  blocks: any
  form?: any
  formHeading?: string
  formOrder: any
  formid?: any
}

const Gutenberg = ({ blocks, form, formHeading, formOrder, formid }: GutenbergData) => {
  const checkOrder = isNaN(formOrder) || formOrder == '' ? false : true
  const formData = { form: form, name: 'form-block' }
  const gutenbergBlocks = checkOrder
    ? [...blocks.slice(0, formOrder), formData, ...blocks.slice(formOrder)]
    : [...blocks, formData]

  if (!blocks && !formid) {
    return null
  }

  return (
    <>
      {gutenbergBlocks.map((block: any, index: number) => {
        const margins = index === 0 ? 'mt-8 mb-20' : 'my-20 md:my-24'
        let attributes: any = {}

        if (block.attributesJSON) {
          attributes = JSON.parse(block.attributesJSON) || ''
        }

        if ('carbon-fields/leipateksti' === block.name) {
          return (
            <CloudContainer
              className={margins}
              key={index}
              block="wysiwyg"
              color={attributes?.data?.gw_cloud}
              side={attributes?.data?.gw_cloud_side}
            >
              <Onview>
                <Wysiwyg classNameInner="max-w-[800px] mx-auto">{attributes?.data?.content}</Wysiwyg>
              </Onview>
            </CloudContainer>
          )
        }
        if ('carbon-fields/keskitetty-lista' === block.name) {
          return (
            <Container key={index} className={margins}>
              <Onview>
                <Wysiwyg classNameInner="max-w-[470px] mx-auto">{block?.dynamicContent}</Wysiwyg>
              </Onview>
            </Container>
          )
        }
        if ('carbon-fields/kuva-ja-teksti' === block.name) {
          return (
            <CloudContainer
              className={margins}
              key={index}
              block="text-image"
              color={attributes?.data?.gw_cloud}
              side={
                attributes?.data?.image_position == '2' || attributes?.data?.image_position == '' ? 'right' : 'left'
              }
            >
              <Onview>
                <TextAndImage
                  img={attributes?.data?.image}
                  heading={attributes?.data?.heading}
                  imgSide={attributes?.data?.image_position}
                  link={attributes?.data?.button.url}
                  linkText={attributes?.data?.button.anchor}
                >
                  {attributes?.data?.content}
                </TextAndImage>
              </Onview>
            </CloudContainer>
          )
        }
        if ('core/image' === block.name) {
          const currentimage: any = getImage(block?.mediaItem?.node?.localFile)

          return (
            <Container defaultWidth={false} classNameInner="max-w-[950px] mx-auto" key={index} className={margins}>
              <Onview>
                <div className="w-full flex justify-center items-center flex-col">
                  <Image alt={block?.mediaItem?.node?.altText} image={currentimage} />
                  {attributes.caption && (
                    <div>
                      <p>{attributes.caption}</p>
                    </div>
                  )}
                </div>
              </Onview>
            </Container>
          )
        }
        if ('form-block' === block.name && formid) {
          return (
            <Container key={index} className={'my-20 md:my-28'}>
              <FromBlock form={block.form} heading={formHeading} />
            </Container>
          )
        }
        if ('core/gallery' === block.name) {
          return (
            <Container defaultWidth={false} classNameInner="max-w-[950px] mx-auto" key={index} className={margins}>
              <Onview>
                <Gallery images={block.innerBlocks} />
              </Onview>
            </Container>
          )
        } else {
          return (
            <Container key={index} className={margins}>
              <Onview>
                <Wysiwyg classNameInner="max-w-[800px] mx-auto">{block?.originalContent}</Wysiwyg>
              </Onview>
            </Container>
          )
        }
      })}
    </>
  )
}

export default Gutenberg
