import React from 'react'
import GravityForm from '@/components/GravityForm'

export interface FullWContainerData {
  heading?: string
  form?: any
}

const FormBlock = ({ heading, form }: FullWContainerData) => {
  return (
    <div className="w-full">
      <div className="w-full grid md:grid-cols-2">
        {heading && (
          <div className="text-primary pr-20">
            <h2 className="mt-10 mb-10 text-big max-w-[400px] tracking-wide">{heading}</h2>
          </div>
        )}
        <div className="">{form && <GravityForm invertColors={true} form={form} />}</div>
      </div>
    </div>
  )
}

export default FormBlock
